<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Inclusive and accessible</h1>
        <h1>digital financial services </h1>
        <h1>for everyone</h1>
        <h4>Quick, convenient & secure instant loan app. Cash transferred directly </h4>
        <h4>to your account. Enjoy flexible repayment options & tenures up to 12 </h4>
        <h4>months.without any hidden fees or commissions. </h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>Our Advantages</h3>
      <div class="flex al-c jc-c">
        <button class="carousel-button" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="slide" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item">
                <img :src="item.img">
                <h1>{{ item.txt }}</h1>
                <p>{{ item.txt2 }}</p>
              </div>
            </transition-group>
          </div>
        </div>
        <button class="carousel-button" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div>
    </div>
    <div id="selector1" class="inner inner3">
      <div class="title">
        <h3>A Few Steps For A Loan</h3>
      </div>
      <div v-for="(item,index) in inner3items" :key="index" class="content">
        <img :src="item.img">
        <div class="flex jc-sb">
          <p>{{ item.txt }}</p>
          <p>{{ item.txt2 }}</p>
          <p>{{ item.txt3 }}</p>
        </div>
      </div>
    </div>
    <div id="selector2" class="inner inner4 flex">
      <div class="content">
        <h1>About Us</h1>
        <p>We are a professional personal loan platform which aims to help people with financial problems</p>
        <p><span>No matter where and when you are in need of cash, we provide you with the simple and secure online product to help you get over the difficulties. Minimal documentation is required. Start to enjoy the flexible process with us.</span></p>
        <h2>Company </h2>
        <p>Company Name: <span>Hollandale Nigeria Limited</span></p>
        <p>Address: <span>84 Adeniran Ogunsanya St, Surulere 101241, Lagos, Nigeria</span></p>
      </div>
      <img src="@/assets/images/inner4_bg.png" alt="">
    </div>
    <div id="selector3" class="inner inner5">
      <h3>Contact Us</h3>
      <div class="flex jc-sb">
        <div v-for="(item,index) in inner5ItemLeftList" :key="index" class="content">
          <img :src="item.img">
          <h4>{{ item.title }}</h4>
          <p>{{ item.txt }}</p>
          <p>{{ item.txt2 ? item.txt2 : '&nbsp;' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Altruism', img: require('@/assets/images/Product01.png') },
        { txt: 'Tenacity', img: require('@/assets/images/Product02.png') },
        { txt: 'Innovate', txt2: 'mortgage', img: require('@/assets/images/Product03.png') },
        { txt: 'Integrity', img: require('@/assets/images/Product04.png') },
        { txt: 'Respect', img: require('@/assets/images/Product05.png') }
      ],
      leftItemList2: [
        { title: 'Download  app', txt: 'Sign Up ' },
        { title: 'Register an account', txt: 'Fill In Info' },
        { title: 'Fill out online forms', txt: 'Waiting For Review' },
        { title: 'Submit the application', txt: 'Get Loan' },
        { title: 'Submit the application', txt: 'Select Amount ', txt2: 'And Duration' },
        { title: 'Submit the application', txt: 'Repay' }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the Tiger Credit app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', txt: 'High approval rate', txt2: 'Easy for everyone to get a loan with a high approval opportunity', img: require('@/assets/images/Product01.png') },
        { id: '2', txt: 'Fast review process', txt2: 'Get approved within 1-3minutes after submitting the application', img: require('@/assets/images/Product02.png') },
        { id: '3', txt: 'Easy and Minimal Documentation', txt2: 'Few documentation is required, easy to apply whenever you need the loan', img: require('@/assets/images/Product03.png') },
        { id: '4', txt: 'Collateral-free', txt2: 'We offer loans based on your credit history and do not require any pledges for security', img: require('@/assets/images/Product04.png') },
        { id: '5', txt: 'No hidden charges', txt2: 'Our platform is completely safe and secure and so are your details', img: require('@/assets/images/Product05.png') },
        { id: '6', txt: 'Safe and Secure', txt2: 'There are no hidden or pre-payment charges', img: require('@/assets/images/Product06.png') }
      ],
      visibleItemCount: document.documentElement.clientWidth > 720 ? 4 : 2,
      currentIndex: 0,
      inner3items: [
        { img: require('@/assets/images/inner3.png'), txt: 'You can download Tiger Credit by search the name in GooglePlay', txt2: 'Register or log in with your mobile number', txt3: 'Only require fill out 3 information form' },
        { img: require('@/assets/images/inner3-2.png'), txt: 'Choose your loan amount and loan term, and submit the application', txt2: 'Loan approved within 1 minute and money disbursed instantly', txt3: 'Timely repayment can increase your loan limit' }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://play.google.com/store/apps/details?id=easy.credit.app.fast'
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    background: #fff;
    .inner{
      width: 100%;
      padding:60px calc((100% - 1100px) / 2);
      margin: 0 auto;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% 100%;
      height: 650px;
      display: flex;
      justify-content: space-between;
      .left_item{
        box-sizing: border-box;
        padding: 60px 0px 0;
        border-radius: 10px;
        h1{
          font-size: 45px;
          color: black;
          font-weight: 700;
          // margin-bottom: 10px;
          line-height: 60px;
          span{
            color: #00BB53;
          }
        }
        h4{
          font-size: 16px;
          line-height: 22px;
          color: #333333;
          font-weight: normal;
          // margin: 0px 0;
        }
        & h4:nth-of-type(1){
          margin-top: 40px;
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 170px;
          .info_img1{
            width: 40px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
        .item_download{
          cursor: pointer;
          margin-top: 80px;
          color: white;
          border-radius: 5px;
          img{
            width: 160px;
            height: 50px;
            margin-right: 5px;
          }
        }
      }
      .right_item{
        img{
          height: 376px;
          margin-right: 30px;
        }
      }
    }
    .inner2{
      background: #F7F7F7;
      padding: 60px calc((100% - 1300px) / 2);
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 60px;
        position: relative;
        &::after{
          display: block;
          content:'';
          width: 80px;
          height: 5px;
          background: #00BB53;
          margin: 20px auto 0;
        }
      }
      p{
        padding: 10px 20px;
        font-size: 12px;
        line-height: 24px;
        color: #666666;
        text-align: center;
      }
      .inner5_item{
        display: flex;
        justify-content: space-between;
        margin: auto;
        .content{
          padding: 30px 10px 20px;
          color: #333333;
          width: 22%;
          border-radius: 15px;
          display: flex;
          // font-weight: 600;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img{
            width: 76px;
            height: 76px;
          }
          p{
            font-size: 13px;
            font-weight: bold;
            padding: 0;
            border-bottom: 0;
          }
        }
      }
      .carousel-container {
        position: relative;
        width: 100%;
        height: 340px;
      }
      .carousel {
        display: flex;
        overflow: hidden;
        height: 100%;
      }
      .carousel-wrapper {
        display: flex;
        transition: transform 0.5s ease;
        width: 100%;
        justify-content:space-around;
        align-items: center;
      }
      .carousel-item {
        width: 260px;
        height: 310px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        color: #fff;
        box-shadow: 0px 3px 15px 1px rgba(93,93,93,0.16);
        border-radius: 5px;
        font-size: 18px;
        // margin:  0 10px;
        h1{
          color: black;
          font-size: 15px;
          line-height: 24px;
          margin-top: 20px;
          text-align: center;
          height: 40px;
          padding: 0 20px;
        }
        p{
          font-size: 12px;
          height: 90px;
        }
        img{
          width: 120px;
        }
      }
      .slide-enter {
        opacity: 0;
        transform: translateX(100%);
      }
      .slide-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: all 0.5s ease;
      }
      .slide-leave-to {
        opacity: 0;
        transform: translateX(-100%);
      }
      .carousel-button {
        // position: absolute;
        padding: 20px;
        font-size: 20px;
        border: none;
        background-color: transparent;
        color: #555;
        outline: none;
        cursor: pointer;
        img{
          width: 20px;
        }
      }
      .carousel-button:hover {
        color: #000;
      }
    }
    .inner3{
      position: relative;
      padding:50px calc((100% - 1000px) / 2) 0;
      .title{
        text-align: center;
        margin-bottom: 100px;
        p{
          color: #666666;
          font-size: 16px;
          margin: 0;
        }
        h3{
          font-size: 24px;
          color: black;
          text-align: center;
          margin-bottom: 30px;
          position: relative;
          &::after{
            display: block;
            content:'';
            width: 80px;
            height: 5px;
            background: #00BB53;
            margin: 20px auto 0;
          }
        }
      }
      .content{
        img{
          width: 100%;
          padding: 0 20px ;
          margin-bottom: 20px;
        }
        p{
          width: 190px;
          text-align: center;
          line-height: 20px;
        }
        margin-bottom: 100px;
      }
    }
    .inner4{
      background: #F7F7F7;
      padding-bottom: 80px;
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 50px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin-top: 20px;
        // }
      }
      img{
        width: 700px;
      }
      .content{
        flex: 1;
        background: white;
        padding: 50px 60px 40px 20px;
        margin: 20px -22px 27px 0;
        p{
          line-height: 24px;
          margin: 20px 0;
          span{
            color: #999999;
          }
        }
      }
    }
    .inner5{
      h3{
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 60px;
        position: relative;
        &::after{
          display: block;
          content:'';
          width: 80px;
          height: 5px;
          background: #00BB53;
          margin: 20px auto 0;
        }
      }
      .content{
        background: #E2F2E2;
        padding: 20px 60px;
        text-align: center;
        h4{
          line-height: 30px;
        }
        img{
          display: block;
          width: 100px;
          margin: 0 auto;
        }
        p{
          line-height: 20px;
          font-size: 12px;
        }
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 410px;
      position: relative;
      background-size: 100% auto;
      .left_item{
        margin: 0px 10px 10px;
        // height: 500px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 14px;
          line-height: 25px;
          // line-height: 50px;
        }
        h4{
          color: black;
          font-size: 12px;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      div{
        width: 100%;
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-button{
        padding: 0 10px;
        img{
          width: 10px;
        }
      }
      .carousel-container{
        height: 300px;
      }
      .carousel-item{
        width: 150px;
        height: 280px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
    .inner3{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      h3{
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content{
        margin-bottom: 50px;
        img{
          width: calc(100vw - 40px) !important;
        }
        div{
          width: calc(100vw - 40px) !important;
          p{
            width: 30%;
            font-size: 12px;
            padding: 0 5px;
          }
        }
      }
      .title{
        width: 100%;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner4{
      padding: 50px calc((100% - 1100px) / 2) ;
      h3{
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      img{
        width: calc(100vw - 40px) !important;
        margin: 0 auto;
      }
      .content{
        width: 200px;
        flex: 1 !important;
        margin: 20px 0 !important;
        padding: 50px 20px;
        p{
          width: 100%;
          font-size: 12px !important;
        }
        // margin-bottom: 20px;
      }
    }
    .inner5{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      div{
        width: 100%;
        flex-direction: column;
        .content{
          margin-bottom: 20px;
        }
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
